import { DictionaryStore } from "dictionary/dictionaryStore";
import { booleanRenderer, dateRenderer, dateTimeRenderer, EwdColumnProps, yesNoBooleanValueProvider } from "helper/GridHelper";
import { DictionaryName, ExciseRegisterReportDto } from "services/GeneratedClient";

interface ExciseEntryColumnProps {
    income: boolean;
    expenditure: boolean;
    // czy kolumna dotyczy zbiorników
    containers?: boolean;
}

interface IProps {
    hasControlOfNegativeStates: boolean;
}

const getExciseRegisterReportColumns = (props: IProps): (ExciseEntryColumnProps & EwdColumnProps<ExciseRegisterReportDto>)[] => {
    return [
        {
            key: "actions",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "id",
            key: "Id",
            title: "Id",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "ordinalNumbers",
            key: "OrdinalNumbers",
            title: "Lp. | Lp. korekty",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "date",
            key: "Date",
            title: "Data",
            render: (_, record: ExciseRegisterReportDto) => dateRenderer(record.date),
            income: true,
            expenditure: true,
            width: 90,
        },
        {
            dataIndex: "productData",
            key: "ProductData",
            title: "Wyrób (Nazwa/Kod/Kod CN)",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "productCode",
            key: "ProductCode",
            title: "Kod Towarowy",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "documentData",
            key: "DocumentData",
            title: "Nr dokumentu (Typ / Data)",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "productNumber",
            key: "ProductNumber",
            title: "Nr pozycji dokumentu",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "invoiceData",
            key: "InvoiceData",
            title: "Faktura (Nr / Data)",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "sourceIdentifier",
            key: "SourceIdentifier",
            title: "Identyfikator źródłowy",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "supplierIdentifier",
            key: "SupplierIdentifier",
            title: "Nr dostawcy",
            income: true,
            expenditure: false,
        },
        {
            dataIndex: "supplierName",
            key: "SupplierName",
            title: "Nazwa dostawcy",
            income: true,
            expenditure: false,
        },
        {
            dataIndex: "supplierAddress",
            key: "SupplierAddress",
            title: "Adres dostawcy",
            income: true,
            expenditure: false,
        },
        {
            dataIndex: "recipientIdentifier",
            key: "RecipientIdentifier",
            title: "Nr odbiorcy",
            income: false,
            expenditure: true,
        },
        {
            dataIndex: "recipientName",
            key: "RecipientName",
            title: "Nazwa odbiorcy",
            income: false,
            expenditure: true,
        },
        {
            dataIndex: "recipientAddress",
            key: "RecipientAddress",
            title: "Adres odbiorcy",
            income: false,
            expenditure: true,
        },
        {
            dataIndex: "transporterIdentifier",
            key: "TransporterIdentifier",
            title: "Nr przewoźnika",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "transporterName",
            key: "TransporterName",
            title: "Nazwa przewoźnika",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "transporterAddress",
            key: "TransporterAddress",
            title: "Adres przewoźnika",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "transportArrangerIdentifier",
            key: "TransportArrangerIdentifier",
            title: "Nr spedytora",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "transportArrangerName",
            key: "TransportArrangerName",
            title: "Nazwa spedytora",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "transportArrangerAddress",
            key: "TransportArrangerAddress",
            title: "Adres spedytora",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "ownerIdentifier",
            key: "OwnerIdentifier",
            title: "Nr właściciela",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "ownerName",
            key: "OwnerName",
            title: "Nazwa właściciela",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "ownerAddress",
            key: "OwnerAddress",
            title: "Adres właściciela",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "importerIdentifier",
            key: "ImporterIdentifier",
            title: "Nr importera",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "importerName",
            key: "ImporterName",
            title: "Nazwa importera",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "importerAddress",
            key: "ImporterAddress",
            title: "Adres importera",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "sellerIdentifier",
            key: "SellerIdentifier",
            title: "Nr sprzedawcy",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "sellerName",
            key: "SellerName",
            title: "Nazwa sprzedawcy",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "sellerAddress",
            key: "SellerAddress",
            title: "Adres sprzedawcy",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "buyerIdentifier",
            key: "BuyerIdentifier",
            title: "Nr nabywcy",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "buyerName",
            key: "BuyerName",
            title: "Nazwa nabywcy",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "buyerAddress",
            key: "BuyerAddress",
            title: "Adres nabywcy",
            income: true,
            expenditure: true,
        },

        {
            dataIndex: "storerOfTobaccoIdentifier",
            key: "StorerOfTobaccoIdentifier",
            title: "Nr magazynującego tytoń poza składem",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "storerOfTobaccoName",
            key: "StorerOfTobaccoName",
            title: "Nazwa magazynującego tytoń poza składem",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "storerOfTobaccoAddress",
            key: "StorerOfTobaccoAddress",
            title: "Adres magazynującego tytoń poza składem",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "finalBuyerIdentifier",
            key: "FinalBuyerIdentifier",
            title: "Nr finalnego nabywcy",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "finalBuyerName",
            key: "FinalBuyerName",
            title: "Nazwa finalnego nabywcy",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "finalBuyerAddress",
            key: "FinalBuyerAddress",
            title: "Adres finalnego nabywcy",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "taxWarehousePlace",
            key: "TaxWarehousePlace",
            title: "Miejsce w składzie",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "incomeInExciseUnit",
            key: "IncomeInExciseUnit",
            title: "Przychód w jednostce rozliczenia akcyzy",
            income: true,
            expenditure: false,
        },
        {
            dataIndex: "containerTo",
            key: "ContainerTo",
            title: "Zbiornik przyjmujący",
            income: false,
            expenditure: false,
            containers: true,
            width: 90,
        },
        {
            dataIndex: "expenditureInExciseUnit",
            key: "ExpenditureInExciseUnit",
            title: "Rozchód w jednostce rozliczenia akcyzy",
            income: false,
            expenditure: true,
        },
        {
            dataIndex: "containerFrom",
            key: "ContainerFrom",
            title: "Zbiornik wydający",
            income: false,
            expenditure: false,
            containers: true,
            width: 90,
        },
        {
            dataIndex: "incomeInInventoryUnit",
            key: "IncomeInInventoryUnit",
            title: "Przychód w jednostce technicznej",
            income: true,
            expenditure: false,
        },
        {
            dataIndex: "expenditureInInventoryUnit",
            key: "ExpenditureInInventoryUnit",
            title: "Rozchód w jednostce technicznej",
            income: false,
            expenditure: true,
        },
        {
            dataIndex: "unitConverter",
            key: "UnitConverter",
            title: "Przelicznik jednostek (gęstość)",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "storedAmountInExciseUnit",
            key: "StoredAmountInExciseUnit",
            title: "Ilość magazynowana w jednostce rozliczenia akcyzy",
            income: true,
            expenditure: true,
            render: (_, record: ExciseRegisterReportDto) => record.storedAmountInExciseUnit &&
                (props.hasControlOfNegativeStates ? record.storedAmountInExciseUnit : Math.abs(record.storedAmountInExciseUnit)),
        },
        {
            dataIndex: "amountOfShortage",
            key: "AmountOfShortage",
            title: "Ubytki z dostawy / przemieszczenia",
            income: false,
            expenditure: true,
        },
        {
            dataIndex: "containerName",
            key: "ContainerName",
            title: "Zbiornik",
            income: false,
            expenditure: false,
            containers: true,
            width: 90,
        },
        {
            dataIndex: "methodOfAcquisition",
            key: "MethodOfAcquisition",
            title: "Sposób nabycia",
            income: true,
            expenditure: false,
        },
        {
            dataIndex: "methodOfDerivation",
            key: "MethodOfDerivation",
            title: "Sposób wyprowadzenia",
            income: false,
            expenditure: true,
        },
        {
            dataIndex: "methodOfConsumptionOfGoods",
            key: "MethodOfConsumptionOfGoods",
            title: "Sposób zużycia wyrobów akcyzowych",
            income: false,
            expenditure: true,
        },
        {
            dataIndex: "methodOfProcessingOfGoods",
            key: "MethodOfProcessingOfGoods",
            title: "Sposób przetworzenia",
            income: false,
            expenditure: true,
        },
        {
            dataIndex: "paragraphOfRegulation",
            key: "ParagraphOfRegulation",
            title: "Paragraf rozporządzenia",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "exciseAmount",
            key: "ExciseAmount",
            title: "Kwota akcyzy",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "fuelExciseAmount",
            key: "FuelExciseAmount",
            title: "Kwota akcyzy paliwowej",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "fiscalMark",
            key: "FiscalMark",
            title: "Informacja o znakach akcyzy",
            render: (_, record: ExciseRegisterReportDto) => booleanRenderer(record.fiscalMark, yesNoBooleanValueProvider),
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "notColouredAndMarkedFuelOils",
            key: "NotColouredAndMarkedFuelOils",
            title: "Informacja o oznakowaniu i barwieniu",
            render: (_, record: ExciseRegisterReportDto) => booleanRenderer(record.notColouredAndMarkedFuelOils, yesNoBooleanValueProvider),
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "cnCodeOfDriedTabacco",
            key: "CnCodeOfDriedTabacco",
            title: "Kod Nomenklatury Scalonej (CN) suszu tytoniowego",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "calorificValue",
            key: "CalorificValue",
            title: "Wartość opałowa",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "localDocumentData",
            key: "LocalDocumentData",
            title: "Dokument logistyczny (Nr/Typ/Nr pozycji)",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "notExemptProductStatus",
            key: "NotExemptProductStatus",
            title: "Status wyrobów niezwolnionych",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "useDescription",
            key: "UseDescription",
            title: "Opis przeznaczenia",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "useMethod",
            key: "UseMethod",
            title: "Sposób użycia",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "transshippedFrom",
            key: "TransshippedFrom",
            title: "Przeładowano z (identyfikator środka transportu)",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "transshippedTo",
            key: "TransshippedTo",
            title: "Przeładowano do (identyfikator środka transportu)",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "concessionNumberForFuels",
            key: "ConcessionNumberForFuels",
            title: "Numer koncesji na obrót paliwami ciekłymi z zagranicą",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "concessionType",
            key: "ConcessionType",
            title: "Typ koncesji",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "concessionIssueDate",
            key: "ConcessionIssueDate",
            title: "Data wydania koncesji",
            render: (_, record: ExciseRegisterReportDto) => dateTimeRenderer(record.concessionIssueDate),
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "concessionExpiryDate",
            key: "ConcessionExpiryDate",
            title: "Data ważności koncesji",
            render: (_, record: ExciseRegisterReportDto) => dateTimeRenderer(record.concessionExpiryDate),
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "saleDate",
            key: "SaleDate",
            title: "Data sprzedaży",
            render: (_, record: ExciseRegisterReportDto) => dateTimeRenderer(record.saleDate),
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "finalCarbonicBuyerIssueDate",
            key: "FinalCarbonicBuyerIssueDate",
            title: "Data wydania wyrobów węglowych finalnemu nabywcy",
            render: (_, record: ExciseRegisterReportDto) => dateTimeRenderer(record.finalCarbonicBuyerIssueDate),
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "customsDeclarationNumber",
            key: "CustomsDeclarationNumber",
            title: "Numer zgłoszenia celnego",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "customsDeclarationDate",
            key: "CustomsDeclarationDate",
            title: "Data zgłoszenia celnego",
            render: (_, record: ExciseRegisterReportDto) => dateTimeRenderer(record.customsDeclarationDate),
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "customsOffice",
            key: "CustomsOffice",
            title: "Miejsce (urząd) importu",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "correctionReason",
            key: "CorrectionReason",
            title: "Powód korekty",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "editorUserMail",
            key: "EditorUserMail",
            title: "Email użytkownika dodającego / edytującego",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "actionDate",
            key: "ActionDate",
            title: "Data wprowadzenia / edycji",
            render: (_, record: ExciseRegisterReportDto) => dateTimeRenderer(record.actionDate),
            income: true,
            expenditure: true,
            width: 90,
        },
        {
            dataIndex: "comments",
            key: "Comments",
            title: "Komentarze",
            income: true,
            expenditure: true,
        },

        {
            dataIndex: "declaration",
            key: "Declaration",
            title: "Deklaracja",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "groupName",
            key: "GroupName",
            title: "Nazwa grupy",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "taxRateDeclaration",
            key: "TaxRateDeclaration",
            title: "Stawka podatku do deklaracji",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "taxExemptionExcise",
            key: "TaxExemptionExcise",
            title: "Zwolnienie od podatku akcyzowego",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "exemptionTitle",
            key: "ExemptionTitle",
            title: "Tytuł zwolnienia",
            income: true,
            expenditure: true,
        },
        {
            dataIndex: "percentageTaxRateDeclaration",
            key: "PercentageTaxRateDeclaration",
            title: "Stawka procentowa podatku do deklaracji",
            income: true,
            expenditure: true,
        },
    ];
};

export default getExciseRegisterReportColumns;
