import { AccountDetailsStore } from "account/AccountDetailsStore";
import { Modal } from "antd";
import { inject, observer } from "mobx-react";
import React from 'react';

export const NoActiveCompany = inject("accountDetailsStore")(observer((props: {accountDetailsStore?: AccountDetailsStore, platformUrl: string | undefined}) => {
    if (props.accountDetailsStore!.noActiveCompany) {
        return <Modal visible footer={null} closable={false} title="Brak przypisanej aktywnej firmy">
                <p>W celu wyjaśnienia sprawy prosimy skontaktować się z administratorami aplikacji</p>
                <a href={props.platformUrl}>Powrót do platformy</a>
            </Modal>
    }

    return <></>
}))